* {
  /* color: #221f1f; */
}
html,
body {
  height: '100%';
  scroll-behavior: smooth;
  overflow-anchor: none;
}
body {
  background-color: '#F7F8FC' !important;
}

.chakra-alert__title {
  color: '#fff !important';
}
